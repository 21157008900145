import React from "react"
import Layout from "../components/Layout"
import Header from "../components/Header"

import SEO from "../components/SEO"

const SayHiPage = () => {
  return (
    <Layout>
      <SEO title="say hi" />
      <section className="flex flex-col items-start px-6 py-5 md:px-10 md:py-8 xl:px-16 xl:py-12 dark:text-darkgray-inverted dark:text-opacity-75">
        <Header showMenu={false} />

        <p className="max-w-full my-16 w-172">
          hey, i am marie grob, a photographer with a passion for analogue as
          well as digital photography. I create stuff in my freetime, if you’re
          interested in collaborating as a model or sth else, just give a quick
          shout out!
        </p>

        <ul>
          <li>
            <a
              className="max-w-full outline-text-link"
              href="mailto:marie.grob99@gmail.com"
              style={{ wordBreak: "break-word" }}
            >
              email // marie.grob99@gmail.com
              <span className="outline-text">→ e-mail me</span>
            </a>
          </li>
          <li>
            <a
              href="https://instagram.com/marie.grob"
              target="_blank"
              rel="noopener noreferrer"
              className="outline-text-link"
            >
              instagram // @marie.grob
              <span className="outline-text">→ follow me</span>
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  )
}

export default SayHiPage
